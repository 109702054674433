module.exports = {
  defaultTitle: 'Abduselam',
  logo: 'https://abduselam.com/favicon/favicon-512.png',
  author: 'Abduselam',
  url: 'https://abduselam.com',
  legalName: 'Abduselam',
  defaultDescription: 'I’m Abduselam and I’m a Software developer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/DevAbduselam',
    github: 'https://github.com/DevAbduselam',
    linkedin: 'https://www.linkedin.com/in/DevAbduselam/',
    instagram: 'https://instagram.com/DevAbduselam',
    // youtube: 'https://www.youtube.com/user/smakoshthegamer',
    // google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
  },
  googleAnalyticsID: 'UA-177638576-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@DevAbduselam',
  },
  address: {
    city: 'Addis Ababa',
    region: 'Region',
    country: 'Ethiopia',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'abduselam@bullal.com',
    phone: '+251961330342',
  },
  foundingDate: '2020',
  recaptcha_key: '6LeTgMoZAAAAAGWFA-IYwj3u2q1kxpHWXu1zkOWH',
};
